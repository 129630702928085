
@media print {
header, .ant-layout-sider, .no-print, .ant-message {
    display: none;
}
}
.ant-list-item.hide {
  background: #d9d9d9;
}
.ant-list-item.active {
  background: #e6f7ff;
}
#googleMap div > img {
  position: absolute;
}
