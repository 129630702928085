h1.index_pageTitle_uPcXl {
  font: normal normal 500 24px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  margin-bottom: 21px;
}
h2.index_cardTitle_3k4qH, .card > .card-header > h2 {
  padding: 10px 20px;
  font: normal normal 500 18px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
  border-left: 4px solid #5068C2;
}
.ant-btn {
  font: normal normal 500 14px/30px "Noto Sans JP", sans-serif;
}
.ant-btn.index_filledBtn_1ljFl {
    background: #5068C2 0% 0% no-repeat padding-box !important;
    border-style: none !important;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal 500 14px/30px "Noto Sans JP", sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    padding: 0 18px;
}
.ant-btn.index_filledBtn_1ljFl span {
      color: #FFFFFF !important;
}
.ant-btn.index_filledBtn_1ljFl:hover {
      opacity: 0.2;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_1ljFl) {
    color: #5068C2 !important;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #D8D8DA;
    border-radius: 4px;
    opacity: 1;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not(.index_filledBtn_1ljFl):hover {
      background: #EFEFF7 0% 0% no-repeat padding-box;
      border: 1px solid #5068C2 !important;
      border-radius: 4px;
      opacity: 1;
}
.ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f2f4f8 !important;
    box-shadow: none !important;
    pointer-events: none;
}
.ant-popover-buttons button {
  line-height: 24px !important;
}
.ant-pagination-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D8D8DA;
  border-radius: 4px;
}
.ant-pagination-item:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #5068C2;
    border-radius: 4px;
}
.ant-pagination-item-active {
  background: #EFEFF7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  color: #5068C2;
}
.ant-list-pagination {
  text-align: center;
}
.ant-pagination {
  text-align: center !important;
}
.ant-list-item:hover {
  background: #EFEFF7 0% 0% no-repeat padding-box;
}
.ant-list-item .ant-list-item-action-split {
  width: 0;
}
.card > .card-header {
  padding-left: 0 !important;
}
.card .card-body {
  padding: 16px !important;
}
@media screen and (max-width: 767px) {
.card .card-body {
      padding-left: 12px !important;
}
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner {
  border-color: #BBBBBB !important;
}
.ant-radio-group :not(.ant-radio-checked) span.ant-radio-inner::after {
    border-color: #BBBBBB !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner {
  border-color: #5068C2 !important;
}
.ant-radio-group .ant-radio-checked span.ant-radio-inner::after {
    background-color: #5068C2 !important;
    border-color: #5068C2 !important;
}
.ant-tabs-nav {
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  color: #3042A0;
  font: normal normal 500 14px/20px "Noto Sans JP", sans-serif;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #3042A0;
    background: #EFEFF7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
}
.ant-tabs-nav .ant-tabs-tab-active {
    background: #3042A0 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    color: #FFF;
}
.ant-tabs-nav .ant-tabs-tab-active:hover {
      background: #3042A0 0% 0% no-repeat padding-box;
      color: #FFF;
}
.ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3042A0 !important;
}
.ant-checkbox-group .ant-checkbox-wrapper {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
    border: 1px solid #BBBBBB;
    border-radius: 2px;
}
.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5068C2;
    border-color: #5068C2;
}
.ant-form .ant-form-item > .ant-form-item-label > label {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
span.index_requiredMark_3YmIN {
  display: inline-block;
  width: 36px;
  height: 20px;
  background: #EF5350 0% 0% no-repeat padding-box;
  border-radius: 2px;
  color: #fff;
  font: normal normal normal 11px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  margin: 8px;
  padding: 1px 6px;
}
.ant-table-wrapper table {
  table-layout: fixed;
  word-break: break-all;
  border-collapse: separate;
  border-radius: 4px;
  border: none !important;
}
.ant-table-wrapper table th {
    border: none !important;
}
.ant-table-wrapper tr {
  font: normal normal 300 14px/14px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-table-wrapper tr.index_stripe0_1qf8y {
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe0_1qf8y td.ant-table-column-sort {
      background: #FAFBFC 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_1acJB {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr.index_stripe1_1acJB td.ant-table-column-sort {
      background: #FFFFFF 0% 0% no-repeat padding-box;
}
.ant-table-wrapper tr td {
    border: none;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter {
  vertical-align: top;
}
.ant-table-wrapper .ant-table-header-column .ant-table-column-sorter .on {
    color: #5068C2 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-small .ant-table-body {
    margin: 0;
}
.ant-table-small .ant-table-body table {
      border-collapse: separate;
      border-radius: 4px;
}
.ant-table-small .ant-table-body table th {
        font: normal normal 500 12px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        background: #EFF0F3 0% 0% no-repeat padding-box !important;
        height: 36px !important;
        padding: 0px 10px !important;
        border: none !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table th {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table td {
        font: normal normal 300 14px/33px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        padding: 0px 10px !important;
}
@media screen and (max-width: 767px) {
.ant-table-small .ant-table-body table td {
            font: normal normal 500 11px/33px "Noto Sans JP", sans-serif;
}
}
.ant-table-small .ant-table-body table tr.index_stripe_oRu-k {
        background: #FAFBFC 0% 0% no-repeat padding-box;
}
.index_map_3hQps {
  overflow-x: hidden;
  position: relative;
}
.index_map_3hQps .card-header {
    border-bottom: 1px solid #e4e9f0 !important;
    padding-bottom: 0;
    padding-top: 11px;
}
.index_sidebar_Oz1ZW {
  width: 24.61rem;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #e4e9f0;
  overflow-y: auto;
  height: 100%;
}
@media (max-width: 991px) {
.index_sidebar_Oz1ZW {
      position: static;
      width: 100%;
      border-right: none;
}
}
.index_sidebar_Oz1ZW .ant-tabs-vertical .ant-tabs-bar .ant-tabs-tab {
    padding: 0;
}
.index_sidebar_Oz1ZW .ant-tabs-vertical.ant-tabs-left .ant-tabs-bar {
    border-right: none;
}
.index_sidebar_Oz1ZW .ant-tabs-bar {
    width: 100%;
}
.index_sidebar_Oz1ZW .ant-tabs-ink-bar {
    background: none;
}
.index_sidebar_Oz1ZW .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab {
    margin: 0;
    color: #514d6a;
}
.index_sidebar_Oz1ZW .ant-tabs-nav .ant-tabs-tab-active .index_tab_qIhju {
    background: #acb7bf;
    color: #fff;
    text-align: left;
}
.index_sidebar_Oz1ZW .ant-tabs-nav .ant-tabs-tab-active .index_tabName_1Gr_B {
    color: #fff;
}
.index_sidebar_Oz1ZW .ant-tabs-nav .ant-tabs-tab-active .index_tabTime_2wPTj {
    color: #fff;
}
.index_sidebar_Oz1ZW .ant-tabs-nav .ant-tabs-tab-active .index_tabText_26xcA {
    color: #fff;
}
.index_sidebarHeader_1koNI {
  padding: 0.84rem 0.76rem;
  border-bottom: 1px solid #e4e9f0;
  background: #EFF0F3 0% 0% no-repeat padding-box;
}
.index_tab_qIhju {
  padding: 1.15rem 1.15rem 1.53rem;
  border-bottom: 1px solid #e4e9f0;
  text-align: left;
}
.index_tab_qIhju:hover {
    background: #f2f4f8;
}
.index_tabAvatar_VC2Js {
  float: left;
}
.index_tabContent_1jO-s {
  padding-top: 0.38rem;
}
.index_tabTime_2wPTj {
  color: #b8beca;
  float: right;
  position: relative;
  top: 0.23rem;
}
.index_tabName_1Gr_B {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 2.3rem;
  text-align: left;
  color: #514d6a;
}
.index_tabText_26xcA {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #b8beca;
  text-align: left;
}
.index_contentHeader_3csgD {
  height: 55px;
}
.index_contentHeader_3csgD .ant-tabs-bar {
    border: none;
}
.index_content_2OwIn {
  margin-right: 24.61rem;
}
.index_content_2OwIn .ant-tabs-bar {
    margin-bottom: 0;
}
@media (max-width: 991px) {
.index_content_2OwIn {
      margin-bottom: 10px;
      margin-right: 0;
      margin-left: 0;
}
}
.index_chat_2XC2k {
  overflow-x: hidden;
  overflow-y: auto;
}
.index_item_2_nIU {
  padding: 0.76rem 0;
}
.index_itemAvatar_2zaqN {
  float: left;
}
.index_itemContent_3Mk2T {
  padding: 0.76rem 1.15rem;
  background: #f2f4f8;
  margin-left: 5.38rem;
  border-radius: 5px;
  position: relative;
}
.index_itemContent_3Mk2T:after {
    position: absolute;
    top: 1.69rem;
    left: -1.08rem;
    width: 0;
    height: 0;
    display: block;
    content: '';
    border: 0.53rem solid transparent;
    border-right-color: #f2f4f8;
}
.index_paymentTransaction_2d--a {
  padding: 1.15rem 1.92rem;
  position: relative;
  border-left: 4px solid #fb434a !important;
}
.index_income_1nePW {
  border-left: 4px solid #46be8a !important;
}
.index_income_1nePW .index_icon_23cKO i {
    color: #46be8a;
}
.index_amount_3VhWj {
  font-size: 1.38rem;
  color: #0e0b20;
  font-weight: bold;
}
.index_info_3Jpfr {
  font-size: 1rem;
  color: #74708d;
  margin-left: 0.38rem;
}
@media (max-width: 991px) {
.index_info_3Jpfr {
      right: auto;
      left: 0.76rem;
}
}
.index_cardWithShadow_wECsU {
  box-shadow: 0 0 2.25rem #e1e1e1;
}
.index_header_1J7In {
  padding: 16px;
  border-bottom: 1px solid #e4e9f0;
}
.index_titleLabel_efugO {
  font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
.index_titleValue_254lw {
  font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.index_tabContainer_gXnt7 {
  padding: 20px;
}
@media screen and (max-width: 767px) {
.index_tabContainer_gXnt7 {
      padding: 16px;
}
}
.index_reportContainer_45ppJ {
  border: 1px solid #E6E6E7;
  border-radius: 4px;
  padding: 40px;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ {
      padding: 16px;
}
}
.index_reportContainer_45ppJ .index_reportTitle_3d8Yt {
    font: normal normal 500 24px/27px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #1F2437;
    margin-bottom: 40px;
    text-align: center;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx {
    padding: 16px;
    background: #FAFBFC 0% 0% no-repeat padding-box;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F {
      width: 40%;
      height: 100%;
      display: inline-block;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F {
          width: 100%;
          padding: 10px;
}
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_avatar_2kMvd {
        display: inline-block;
        width: 50%;
        height: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: #5068C2;
        border: 1px solid #5068C2;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_driverInfo_B4Kn6 {
        display: inline-block;
        height: 100%;
        margin-left: 12px;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_driverInfo_B4Kn6 .index_label_DM3-I {
          display: block;
          font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
          letter-spacing: 0px;
          color: #787B87;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_driverInfo_B4Kn6 .index_value_MFZeJ {
          display: block;
          font: normal normal bold 16px/20px "Noto Sans JP", sans-serif;
          letter-spacing: 0px;
          color: #1F2437;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_infoItem_EVFte {
        margin-bottom: 8px;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_infoItem_EVFte .index_label_DM3-I {
          font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
          letter-spacing: 0px;
          color: #787B87;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_leftInfo_1Va7F .index_infoItem_EVFte .index_value_MFZeJ {
          font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
          letter-spacing: 0px;
          color: #1F2437;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_rightInfo_2RJEH {
      width: 60%;
      height: 100%;
      display: inline-block;
      border-left: 1px solid #E6E6E7;
      padding-left: 8px;
      vertical-align: middle;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_rightInfo_2RJEH {
          width: 100%;
          padding-top: 15px;
}
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_rightInfo_2RJEH {
          border-left: none;
          border-top: 1px solid #E6E6E7;
}
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_rightInfo_2RJEH .index_infoItem_EVFte {
        margin-bottom: 8px;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_rightInfo_2RJEH .index_infoItem_EVFte .index_label_DM3-I {
          font: normal normal normal 14px/20px "Noto Sans JP", sans-serif;
          letter-spacing: 0px;
          color: #787B87;
}
.index_reportContainer_45ppJ .index_driveInfoContainer_2onvx .index_rightInfo_2RJEH .index_infoItem_EVFte .index_value_MFZeJ {
          font: normal normal bold 14px/20px "Noto Sans JP", sans-serif;
          letter-spacing: 0px;
          color: #1F2437;
}
.index_reportContainer_45ppJ h4 {
    font: normal normal bold 16px/20px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #1F2437;
    border-left: 3px solid #1F2437;
    padding-left: 8px;
    margin-top: 32px;
    margin-bottom: 15px;
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG {
    padding: 20px;
    background: rgba(56, 142, 60, 0.1) 0% 0% no-repeat padding-box;
    border-top: 2px solid #388E3C;
    margin: 14px 0 8px;
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_percentContainer_1Vbuq {
      display: inline-block;
      width: 50%;
      text-align: left;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_percentContainer_1Vbuq {
          display: block;
          width: 100%;
}
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_percentContainer_1Vbuq .index_percentLabel_1uvSR {
        font: normal normal bold 18px/20px "Noto Sans JP", sans-serif;
        letter-spacing: 0px;
        color: #1F2437;
        margin-right: 10px;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_percentContainer_1Vbuq .index_percentLabel_1uvSR {
            font: normal normal bold 16px/16px "Noto Sans JP", sans-serif;
}
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_percentContainer_1Vbuq .index_percentValue_1Eopm {
        font: normal normal normal 28px/34px Lato;
        letter-spacing: 1.4px;
        color: #1F2437;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_percentContainer_1Vbuq .index_percentValue_1Eopm {
            font: normal normal normal 26px/32px Lato;
            letter-spacing: 1.3px;
}
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_numContainer_2BXxx {
      display: inline-block;
      width: 50%;
      text-align: right;
      letter-spacing: 0px;
      color: #787B87;
}
@media screen and (max-width: 767px) {
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_numContainer_2BXxx {
          display: block;
          width: 100%;
          margin-top: 15px;
}
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_numContainer_2BXxx i {
        font-size: 16px;
        margin-right: 6px;
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_numContainer_2BXxx .index_numLabel_2UaCo {
        font: normal normal normal 12px/20px "Noto Sans JP", sans-serif;
        margin-right: 2px;
}
.index_reportContainer_45ppJ .index_percentArea_2zrgG .index_numContainer_2BXxx .index_numValue_1UMP6 {
        font: normal normal normal 16px/19px Lato;
}
.ant-list-item-meta-title {
  font: normal normal normal 14px/24px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #787B87;
}
.ant-list-item-meta-content {
  font: normal normal normal 14px/24px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
  color: #1F2437;
}
.ant-list-item-meta-content .index_speedValue_1iF33 {
    font: normal normal bold 14px/24px "Noto Sans JP", sans-serif;
    letter-spacing: 0px;
    color: #1F2437;
}
.index_ant-checkbox_1SamY + span {
  font: normal normal normal 14px/33px "Noto Sans JP", sans-serif;
  letter-spacing: 0px;
}
.ant-layout-content .ant-tabs-nav .ant-tabs-tab {
  margin-right: 8px !important;
}
.daily-report .ant-table-tbody .ant-table-row {
  height: 36px;
}
.color-red {
  color: #fb434a;
}
